<template>
  <div @click="toggle(id)">
    <slot><button>Toggle</button></slot>
  </div>
</template>

<script>
export default {
  name: 'SlideOverLeftTrigger',
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  mounted: function() {
    // console.log("mounted");
  },
  methods: {
    // fire event
    open(custom = null) {
      let eventName = custom !== null ? custom : this.id
      let c = dispatchEvent(new Event(this.getOpeningEventName(eventName)))
      console.log(c)
      // let o = dispatchEvent(new Event(this.openingEvent));
      //console.log(o)
    },
    close(custom = null) {
      console.log('cancel = ')
      let eventName = custom !== null ? custom : this.id
      dispatchEvent(new Event(this.getClosingEventName(eventName)))
      // let c = dispatchEvent(new Event(this.closingEvent));
      //console.log(c)
    },
    toggle(custom = null, awaitFor = 100) {
      let eventName = custom !== null ? custom : this.id
      let xEventName = this.getTogglingEventName(eventName)

      console.log(xEventName)

      this.$emit('cancelEdit', xEventName, awaitFor)

      return
      // let t = dispatchEvent(new Event(this.togglingEvent));
      //console.log(t)
    },
    getOpeningEventName(id) {
      return `sor-open-${id}`
    },
    getClosingEventName(id) {
      return `sor-close-${id}`
    },
    getTogglingEventName(id) {
      return `sor-toggle-${id}`
    },
  },
}
</script>

<style></style>
